/* src/pages/NotFound.css */

.not-found {
    text-align: center;
    margin-top: 100px;
  }
  
  .not-found h1 {
    font-size: 10rem;
    margin: 0;
  }
  
  .not-found p {
    font-size: 1.5rem;
  }
  
  .not-found a {
    color: #007bff;
    text-decoration: none;
  }
  
  .not-found a:hover {
    text-decoration: underline;
  }
  