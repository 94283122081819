.tree {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .person {
    display: inline-block;
    padding: 10px;
    margin: 5px;
    padding-right: 0;

  }
  
  .children {
    margin-top: 10px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .name {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 10px;
    font-size: 15px;
  }
  .name span{
    cursor: pointer;
  }
  .name a{
    color: #222;
  }
  .icn-blue{
    color: #00205c !important;
  }
  .icn-yellow{
    color: #C79211 !important;
  }