:root {
    --primary-color: #007bff;
    --secondary-color: #222747;
    --theme-dark-color: #44546A;
    --light-blue-color: #5E82E2;
}

body {
    background: #EEF0F4;
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #495057;
    /* background: url("../images/bg.png") no-repeat 100% 90% #f4f4f4; */
    font-size: 13px;
    overflow-x: hidden;
}

.login_page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../images/bg.jpg) no-repeat left center #fff;
    position: relative;
    background-size: cover;
    flex-direction: column;
}

.login_page .loin_area {
    max-width: 510px;
    width: 100%;
    padding: 1.5rem;
    border-radius: 15px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
    background: #fff;
}


/* .login_page:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: url("../images/bg-graphics.png") no-repeat #B32F84;
  background-size: cover;
} */

.login_page .loin_area h3 {
    color: #000;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 25px;
}


/* .login_page .loin_area h3:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 20px;
    height: 1px;
    background: #B32F84;
    transform: translateX(-50%);
} */

.login_page .loin_area p {
    font-size: 17px;
    font-weight: 300;
    color: var(--theme-dark-color);
    text-align: center;
    padding: 0;
    margin: 0;
}

.login_page .loin_area .loin_input {
    margin: 20px 0 0px 0;
    padding: 0;
    position: relative;
}

.login_page .loin_area .loin_input .input_box {
    background: none;
    border: 0;
    border-bottom: 1px solid #e7e7e7;
    padding: 8px 40px 8px 0px;
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    color: var(--theme-dark-color);
    text-align: left;
    width: 100%;
}

.login_page .loin_area .loin_input .input_box:focus-visible {
    box-shadow: none;
    outline: none;
}

.login_page .loin_area .loin_input .material-symbols-outlined {
    position: absolute;
    right: 0;
    top: 10px;
    color: var(--theme-dark-color);
    font-size: 22px;
}

.login_page .loin_area .loin_input {
    margin: 20px 0 0px 0;
    padding: 0;
    position: relative;
}

.login_page .loin_area .loin_input .input_box {
    background: none;
    border: 0;
    border-bottom: 1px solid #e7e7e7;
    padding: 8px 40px 8px 0px;
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    color: var(--theme-dark-color);
    text-align: left;
    width: 100%;
}

.login_page .loin_area .dont_account a {
    text-decoration: none;
    color: #B32F84;
}

.right-0 {
    right: 0;
}

.top-0 {
    right: 0;
}

.login_page .loin_area .login_logo img {
    max-height: 98px;
}

.btn11 {
    top: 0px;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    margin: 0 8px;
    border-radius: 4px;
    display: inline-block;
    text-transform: uppercase !important;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    color: #fff;
    cursor: hand;
    text-align: center;
    text-transform: capitalize;
    position: relative;
    overflow: hidden !important;
    transition: all 0.3s ease-in-out;
    background: transparent !important;
    z-index: 1;
}

.btn11::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: #B32F84;
    position: absolute;
    left: 0%;
    opacity: 1;
    top: 0;
    z-index: -12;
    transition: all 0.65s cubic-bezier(0.77, 0, 0.175, 1);
}

.btn11::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: #5E82E2;
    position: absolute;
    left: 0%;
    opacity: 1;
    top: 0;
    z-index: -15;
    transition: all 0.65s cubic-bezier(0.77, 0, 0.175, 1);
}

.btn11:hover {
    border: 1px solid transparent;
    color: #B32F84 !important;
}

.btn11:hover::before,
.btn11:hover::before {
    opacity: 1;
    transform: translateX(100%);
}

.btn11:hover::after,
.btn11:hover::after {
    opacity: 1;
    transform: translateX(-100%);
}

.login_page .loin_area .remember_txt label {
    font-size: 14px;
    font-weight: 300;
    color: var(--theme-dark-color);
    text-align: left;
}

img {
    max-width: 100%;
}

.bg-dark.bg-dark-cutom {
    background: #00205c !important;
    /* box-shadow: 0px 12px 24px 0px rgba(18, 38, 63, 0.03) !important; */
}

.bg-dark.bg-dark-cutom .nav-link {
    font-size: 14px;
    font-weight: normal;
    white-space: nowrap;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-600 {
    font-weight: 600;
}

.csmt-breadcrumb {
    list-style-type: none;
}

.csmt-breadcrumb li {
    font-size: 13px;
    color: #74788D;
    position: relative;
}

.csmt-breadcrumb li::before {
    content: "/";
    position: absolute;
    top: 0;
    right: -12px;
    display: block;
    color: #495057;
}

.csmt-breadcrumb li:last-child::before {
    display: none;
}

.csmt-breadcrumb li a {
    color: #495057;
    text-decoration: none;
}

.white-box {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 10px 20px 0px rgba(18, 38, 63, 0.03);
    padding: 20px 26px;
}

.custom-file-uploader {
    border-radius: 4px;
    border: 2px dashed #CED4DA;
    background: #FFF;
    height: 134px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
}

.custom-file-uploader input {
    position: absolute;
    inset: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
}

.file-upload-icon {
    background: url("../images/file-upload.svg") no-repeat 0 0;
    width: 26px;
    height: 18px;
    flex-shrink: 0;
    background-size: cover;
    display: block;
}

.form-area label {
    font-size: 13px;
    margin-bottom: 0.6rem;
    display: block;
}

::-webkit-input-placeholder {
    font-size: 16px;
}

::-moz-placeholder {
    font-size: 16px;
}

:-ms-input-placeholder {
    font-size: 16px;
}

::-ms-input-placeholder {
    font-size: 16px;
}

::placeholder {
    font-size: 16px;
}

.btn {
    font-size: 13px;
}

.form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.btn-primary {
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.btn-cancel {
    background: #E6E6E6 !important;
    border-color: #E6E6E6 !important;
    color: #000;
}

.btn-cancel:hover {
    background: #cacaca !important;
    border-color: #cacaca !important;
    color: #000;
}

.btn-primary:hover {
    background-color: color-mix(in oklab, var(--primary-color), black 20%) !important;
    border-color: color-mix(in oklab, var(--primary-color), black 20%) !important;
    color: #fff;
}

.btn-info {
    background: var(--light-blue-color) !important;
    border-color: var(--light-blue-color) !important;
    color: #fff;
}

.btn-info:hover {
    background-color: color-mix(in oklab, var(--light-blue-color), black 20%) !important;
    border-color: color-mix(in oklab, var(--light-blue-color), black 20%) !important;
    color: #fff;
}

.bell-icon {
    background: url("../images/bell-icon.svg") no-repeat 0 0;
    width: 22px;
    height: 22px;
    background-size: cover;
    display: block;
    flex-shrink: 0;
}

.setting-icon {
    background: url("../images/setting-icon.svg") no-repeat 0 0;
    width: 22px;
    height: 22px;
    background-size: cover;
    display: block;
    flex-shrink: 0;
}

.top-avatar {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    overflow: hidden;
}

.user-link {
    font-size: 13px;
    color: #E9ECEF;
    text-decoration: none;
    border-radius: 27px;
    background: rgba(255, 255, 255, 0.25);
    padding: 5px 10px 5px 5px;
}

.user-link.dropdown-toggle::after {
    content: "";
    background: url(../images/drop-white.svg) center center no-repeat;
    width: 10px;
    height: 10px;
    border: 0 none;
}

.user-dropdown {
    position: relative;
}

.user-dropdown::before {
    content: "";
    left: -15px;
    top: 10px;
    background: rgba(255, 255, 255, 0.10);
    width: 1px;
    height: 18px;
    display: flex;
    position: absolute;
}

.footer {
    background: rgba(0, 0, 0, 0);
}

.footer p {
    color: #757575;
    font-size: 13px;
    text-align: center;
    margin-bottom: 0;
}

.sub-nav-menu .bg-dark-cutom {
    background: #00205c !important;
    padding-bottom: 0px;
}

.bg-dark.bg-dark-cutom .nav-link {
    color: #fff;
    opacity: 0.5;
    min-height: 42px;
}

.bg-dark.bg-dark-cutom .nav-link.active {
    opacity: 1;
    background-color: #185cb5;
    border-radius: 10px 10px 0 0;
}


/* 
.home-icon {
    background: url("../images/bx-home-circle.svg") no-repeat;
    width: 15px;
    height: 15px;
    display: block;
    flex-shrink: 0;
}

.airport-icon {
    background: url("../images/bx-tone.svg") no-repeat;
    width: 15px;
    height: 15px;
    display: block;
    flex-shrink: 0;
}

.usecase-icon {
    background: url("../images/bx-customize.svg") no-repeat;
    width: 15px;
    height: 15px;
    display: block;
    flex-shrink: 0;
}

.report-icon {
    background: url("../images/bx-collection.svg") no-repeat;
    width: 15px;
    height: 15px;
    display: block;
    flex-shrink: 0;
} */

.form-check-input:checked {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.sticky-nav {
    z-index: 999;
}

.arrow-right {
    background: url("../images/chevron-right.svg") no-repeat 0 0;
    width: 13px;
    height: 13px;
    display: block;
    flex-shrink: 0;
}

.start-idea-wrapper {
    max-width: 685px;
    margin-left: auto;
    margin-right: auto;
}

.font-weight-400 {
    font-weight: 400;
}

.list-table td {
    font-size: 13px;
    font-weight: 300;
    vertical-align: middle;
    padding: 5px 10px;
}

.list-table td h6 {
    font-size: 13px;
    font-weight: 500;
}

.list-table td p {
    margin-bottom: 0;
    color: #74788D;
}

.list-table thead th {
    /* background: #FFF1FA; */
    background: #1560C5;
    /* border-top: 1px solid #FADCEF; */
    border-top: 1px solid #E1DFF0;
    border-bottom: 2px solid #E1DFF0;
    color: #FFF;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    background: #fff !important;
    --bs-table-bg-type: none !important;
    border: none !important;
}

.table-striped>tbody>tr:nth-of-type(even)>* {
    background: #eee !important;
    border: none !important;
}

.table-filter-panel {
    background: var(--secondary-color);
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
}

.tags {
    padding: 0.3rem 0.8rem;
    background: var(--primary-color);
    color: white;
    border-radius: 0.5rem;
    white-space: nowrap;
    font-size: 9px;
}

.flex-1 {
    flex: 1;
}

.table-filter-panel label {
    font-size: 13px;
    font-weight: 400;
    color: white;
}

.w-5 {
    width: 5%;
}

.date-range-flds input {
    width: 172px;
    height: 39px;
}

.date-range-flds input:nth-child(1) {
    border-radius: 4px 0 0 4px;
}

.date-range-flds input:nth-child(2) {
    border-radius: 0 4px 4px 0;
}

.pagination-panel ul {
    list-style-type: none;
}

.pagination-panel ul li {
    font-size: 13px;
    margin: 0 2.5px;
}

.pagination-panel ul li a {
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 5rem;
    color: #74788D;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pagination-panel ul li a:hover {
    background: #EFF2F7;
}

.pagination-panel ul li.active a {
    background: #C79211;
    color: white;
}

.navbar-brand {
    border-right: 1px solid rgba(255, 255, 255, 0.13);
    padding: 0;
    padding-right: 1rem;
}

.navbar-brand img {
    max-height: 55px;
}

/* .navbar-nav{
  overflow-x: auto;
  overflow-y: visible;
  max-width: 98vw;
} */

/* .navbar-nav::-webkit-scrollbar {
    width: 0 !important;
} */

.left-panel {
    background: var(--secondary-color);
    width: 250px;
    padding: 1rem 0;
    border-radius: 5px;
    margin-right: 21px;
    position: relative;
    transition: all 0.2s ease-in;
}

.left-panel.collapse-panel {
    width: 5px;
}

.left-panel .accordion {
    transition: all 0.3s ease-in;
    overflow: hidden;
}

.left-panel.collapse-panel .accordion {
    opacity: 0;
    overflow: hidden;
}

.right-panel {
    flex-grow: 1;
}

.number-avatar {
    width: 32px;
    height: 32px;
    background: var(--light-blue-color);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    border-radius: 5rem;
    flex-shrink: 0;
}

.edit-icon {
    background: url("../images/edit.svg") no-repeat 0 0;
    width: 24px;
    height: 24px;
    display: block;
    background-size: cover;
    flex-shrink: 0;
}

.delete-icon {
    background: url("../images/trash.svg") no-repeat 0 0;
    width: 24px;
    height: 24px;
    display: block;
    background-size: cover;
    flex-shrink: 0;
}

.upload-icon {
    background: url("../images/upload.svg") no-repeat 0 0;
    width: 24px;
    height: 24px;
    display: block;
    background-size: cover;
    flex-shrink: 0;
}

.progress-steps-panel {
    background: #FFF;
    box-shadow: 0px 12px 24px 0px rgba(18, 38, 63, 0.03);
    border-radius: 5px;
    overflow: hidden;
}

.progress-steps {
    flex: 1;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
}

.progress-steps.active {
    background: var(--light-blue-color);
    color: white;
    border-radius: 5px;
}

.progress-steps.active .progress-number {
    background: white;
    color: #5E82E2;
}

.progress-steps p {
    margin-bottom: 0;
    font-size: 13px;
}

.progress-number {
    width: 36px;
    height: 36px;
    background: var(--light-blue-color);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 10rem;
    flex-shrink: 0;
}

.light-blue-bg {
    background: #EFF6FC;
    box-shadow: 0px 12px 24px 0px rgba(18, 38, 63, 0.02);
    border-radius: 4px;
    padding: 15px 26px;
}

.uploaded-files {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 12px 24px 0px rgba(18, 38, 63, 0.02);
    padding: 1rem;
}

.file-upload-progress p {
    font-size: 13px;
    margin-bottom: 0.2rem;
}

.green-text {
    color: #1EBB2E !important;
}

.file-upload-progress-bar {
    background: var(--theme-dark-color);
    height: 4px;
    border-radius: 50px;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.file-upload-progress-percent {
    background: #1EBB2E !important;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 50px;
}

.close-icon {
    background: url("../images/close.svg") no-repeat 0 0;
    width: 24px;
    height: 24px;
    background-size: cover;
    flex-shrink: 0;
    display: block;
}

.collapse-arrow {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #B4B4B4;
    position: absolute;
    top: 50%;
    right: -12px;
    border-radius: 100px;
}

.collapse-arrow-icon {
    background: url("../images/forward.svg") no-repeat 0 0;
    width: 24px;
    height: 24px;
}

.left-panel .accordion-item,
.left-panel .accordion,
.left-panel .accordion .accordion-button {
    background: transparent !important;
    box-shadow: none !important;
}

.left-panel .accordion .accordion-button {
    color: white;
    font-size: 13px;
    font-weight: 400;
}

.left-panel .accordion-item {
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #4B517A !important;
}

.left-panel .accordion-body ul.submenu {
    list-style-type: none;
    padding: 0 0 0 1rem;
}

.left-panel .accordion-body ul.submenu li a {
    color: #BCC1DA;
    font-size: 12px;
    line-height: 30px;
    font-weight: normal;
    text-decoration: none;
    position: relative;
    gap: 5px;
    display: flex;
    align-items: center;
}

.left-panel .accordion-body ul.submenu li a::before {
    content: '';
    background: url("../images/menu-arrow.svg") no-repeat 0 0;
    background-size: cover;
    width: 7px;
    height: 4px;
    display: block;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.left-panel .accordion-button {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.left-panel .accordion-button:not(.collapsed)::after {
    background: url("../images/accordian-arrow.svg") no-repeat 50% 50%;
}

.left-panel .accordion-button::after {
    background: url("../images/accordian-arrow.svg") no-repeat 50% 50%;
}

.menu-icon {
    display: block;
    flex-shrink: 0;
    margin-right: 0.5rem;
}

.left-panel .accordion-body {
    padding-top: 0;
}

.w-f-125 {
    width: 125px;
}

.w-f-200 {
    width: 200px;
}

.text-purple {
    color: #B32F84;
}

.border-light {
    border-color: #F1F1F1 !important;
}

.text-xs {
    font-size: 11px;
}

.text-sm {
    font-size: 13px;
}

a {
    text-decoration: none;
}

.text-grey {
    color: #74788D;
}

.idea-stats-panel {
    background: var(--secondary-color);
    margin-left: -20px;
    margin-right: -20px;
}

.idea-stats-block {
    padding: 0.5rem;
    text-align: center;
}

.idea-stats-block h5 {
    font-size: 12px;
    color: white;
}

.idea-stats-block h6 {
    font-size: 15px;
    color: #FF4EC0;
    margin-bottom: 0;
}

.idea-stats-section {
    width: 155px;
    background: #B32F84;
    padding: 0.5rem;
    text-align: center;
}

.idea-stats-section h5 {
    font-size: 12px;
}

.idea-stats-section h6 {
    font-size: 13px;
    margin-bottom: 0;
}

.can-toggle {
    position: relative;
    width: 200px;
}

.can-toggle *,
.can-toggle *:before,
.can-toggle *:after {
    box-sizing: border-box;
}

.can-toggle input[type=checkbox] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.can-toggle input[type=checkbox][disabled]~label {
    pointer-events: none;
}

.can-toggle input[type=checkbox][disabled]~label .can-toggle__switch {
    opacity: 0.4;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:before {
    content: attr(data-unchecked);
    left: 0;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:after {
    content: attr(data-checked);
}

.can-toggle label {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    display: flex;
    align-items: center;
}

.can-toggle label .can-toggle__label-text {
    flex: 1;
    padding-left: 32px;
}

.can-toggle label .can-toggle__switch {
    position: relative;
}

.can-toggle label .can-toggle__switch:before {
    content: attr(data-checked);
    position: absolute;
    top: 0;
    text-transform: uppercase;
    text-align: center;
}

.can-toggle label .can-toggle__switch:after {
    content: attr(data-unchecked);
    position: absolute;
    z-index: 5;
    text-transform: uppercase;
    text-align: center;
    background: var(--light-blue-color);
    transform: translate3d(0, 0, 0);
}

.can-toggle input[type=checkbox][disabled]~label {
    color: rgba(234, 0, 48, 0.5);
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch {
    background-color: white;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:after {
    color: white;
}

.can-toggle label .can-toggle__label-text {
    flex: 1;
}

.can-toggle label .can-toggle__switch {
    transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
    background: white;
}

.can-toggle label .can-toggle__switch:before {
    color: rgba(0, 0, 0, 0.8);
}

.can-toggle label .can-toggle__switch:after {
    transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    color: white;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:after {
    transform: translate3d(65px, 0, 0);
}

.can-toggle label {
    font-size: 14px;
}

.can-toggle label .can-toggle__switch {
    height: 36px;
    flex: 0 0 134px;
    border-radius: 4px;
    border: 1px solid var(--light-blue-color);
}

.can-toggle label .can-toggle__switch:before {
    left: 67px;
    font-size: 12px;
    line-height: 36px;
    width: 67px;
    padding: 0 12px;
}

.can-toggle label .can-toggle__switch:after {
    top: 0;
    left: 0;
    border-radius: 2px;
    width: 67px;
    line-height: 36px;
    font-size: 12px;
}

.data-not-found {
    font-size: 20px;
    color: palevioletred;
}

.can-toggle.can-toggle--size-small input[type=checkbox]:checked~label .can-toggle__switch:after {
    transform: translate3d(44px, 0, 0);
}

.can-toggle.can-toggle--size-small label {
    font-size: 13px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch {
    height: 28px;
    flex: 0 0 90px;
    border-radius: 2px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:before {
    left: 45px;
    font-size: 10px;
    line-height: 28px;
    width: 45px;
    padding: 0 12px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:after {
    top: 1px;
    left: 1px;
    border-radius: 1px;
    width: 44px;
    line-height: 26px;
    font-size: 10px;
}

.w-10 {
    width: 10% !important;
}

select.form-control {
    font-size: 13px;
    -webkit-appearance: revert;
       -moz-appearance: revert;
            appearance: revert;
    height: 39px;
}


/* .modal-content {
    background-image: url("../images/bg-flip.png");
    background-repeat: no-repeat;
} */

.modal-title {
    color: #000;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.modal-header .btn-close {
    background-image: none;
    padding: 0;
    width: 30px;
    height: 30px;
    opacity: 1;
    right: 10px;
    position: relative;
}

.modal-header .btn-close img {
    width: 100%;
}

.modal-body .form-select {
    background-color: #F2F1F9;
    height: 50px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Manrope', sans-serif;
}

.username-icon {
    background: url("../images/username-icon.svg") no-repeat;
    width: 24px;
    height: 28px;
    background-size: cover;
    flex-shrink: 0;
    display: block;
}

.password-icon {
    background: url("../images/password-icon.svg") no-repeat;
    width: 26px;
    height: 27px;
    background-size: cover;
    flex-shrink: 0;
    display: block;
}

.mail-icon {
    background: url("../images/mail-icon.svg") no-repeat;
    width: 34px;
    height: 32px;
    background-size: cover;
    flex-shrink: 0;
    display: block;
}

.border-radius-0 {
    border-radius: 0 !important;
}

.form-check-label {
    margin-bottom: 0 !important;
    line-height: 23px;
}

.min-height-200 {
    min-height: 200px;
}

.three-col-block {
    width: 31.66%;
    margin-right: 2.5%;
    margin-bottom: 30px;
    position: relative;
}

.three-col-block:nth-child(3n) {
    margin-right: 0;
}

.three-col-block::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10px;
    background: #5E82E2;
    display: block;
    height: 3px;
    width: 78px;
}

.table th {
    white-space: nowrap;
}

.mob-menu-wrapper {
    display: none;
}

.navbar-toggler {
    border: none;
}

.masonry-idea-list .card img,
.masonry-idea-list .card {
    border-radius: 11px;
    overflow: hidden;
}

.notification-btn {
    position: relative;
}

.notification-count {
    position: absolute;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #F46A6A;
    color: #fff;
    font-size: 10px;
    top: -7px;
    right: -6px;
}

.dropdown-menu.top-dropdown {
    background: #00205c !important;
    font-size: 13px !important;
}

.top-dropdown .dropdown-item {
    background: url('../images/menu-list.svg') 10px center no-repeat;
    padding-left: 35px;
    position: relative;
    padding-right: 35px;
}

.top-dropdown .dropdown-item i {
    display: inline-flex;
    margin: 0 0 0 5px;
    position: absolute;
    right: 10px;
    top: 6px;
    z-index: 1;
}

.dropdown-menu.top-dropdown .dropdown-item.active,
.dropdown-menu.top-dropdown .dropdown-item:active,
.dropdown-menu.top-dropdown .dropdown-item:focus,
.dropdown-menu.top-dropdown .dropdown-item:hover {
    background: #185cb5 url('../images/menu-list-white.svg') 10px center no-repeat !important;
}

.page-nav-arrow-left {
    background: url("../images/bx-chevron-left.svg") no-repeat 50% 50%;
    background-size: cover;
    width: 30px;
    height: 30px;
    display: block;
    flex-shrink: 0;
}

.page-nav-arrow-right {
    background: url("../images/bx-chevron-right.svg") no-repeat 50% 50%;
    background-size: cover;
    width: 30px;
    height: 30px;
    display: block;
    flex-shrink: 0;
}

.alphabet-avatar {
    background: var(--light-blue-color);
    width: 66px;
    height: 55px;
    font-size: 24px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.for-mob {
    display: none !important;
}

.more-icon {
    background: url("../images/more-icon.svg") no-repeat 0 0;
    width: 15px;
    height: 15px;
    background-size: cover;
    display: block;
    flex-shrink: 0;
}

.more-menu-panel {
    background: var(--secondary-color) no-repeat 0 0;
    position: fixed;
    right: 0;
    width: 200px;
    bottom: 69px;
    border-radius: 15px;
    padding: 20px 15px;
    z-index: 99;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    transform: translateY(500px);
    transition: all 0.3s ease-in;
}

.more-menu-panel.show-menu {
    transform: translateY(0);
}

.more-menu-panel>ul {
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;
}

.more-menu-panel>ul li {
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.more-menu-panel>ul li a {
    color: white;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--light-blue-color);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
    border-radius: 10px;
}


/* 9.10.23 */

.table-center-form-check {
    display: inline-block;
    margin: 0 auto;
    padding: 0;
}

.table-center-form-check .form-check-input {
    margin-left: 0;
    margin-top: 6px;
}

.wh-20 {
    width: 20px;
    height: 20px;
}

.h-60 {
    height: 60px;
}

.check-done {
    font-size: 0;
    background-image: url(../images/check-done.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.badge.bg-secondary {
    background-color: var(--secondary-color) !important;
    padding: 6px;
    font-size: 13px;
    font-weight: 400;
    min-width: 100px;
}

.badge.bg-info {
    background-color: #9723AC !important;
    padding: 6px;
    font-size: 13px;
    font-weight: 400;
    min-width: 100px;
}

.in-active {
    width: 94px;
    height: 24px;
    display: block;
    background-size: cover;
    background: url(../images/in-active.png) no-repeat 0 0;
}

.active-icon {
    width: 94px;
    height: 24px;
    display: block;
    background-size: cover;
    background: url(../images/active.png) no-repeat 0 0;
}

.right-float-bar {
    position: fixed;
    top: 80px;
    right: 0;
    width: 0;
    background-color: #fff;
    box-shadow: 0px 0px 68px 0px rgba(0, 0, 0, 0.20);
    padding: 22px;
    z-index: 1000;
    transform: translateX(675px);
    transition: all 0.3s ease;
}

.right-float-bar.collapse-panel-right {
    transform: translateX(0);
    width: 675px;
}

.right-float-bar-body {
    overflow-y: auto;
    height: calc(100vh - 130px);
    padding: 22px;
}

.right-float-bar .time-cancel {
    position: absolute;
    right: 45px;
    top: 15px;
    z-index: 1;
    background-color: #fff;
    border-radius: 50px;
}

.uploaded-files-two {
    background-color: #fff;
    border: 2px solid #F1F1F1;
}

.modal.right.fade .modal-dialog {
    right: -320px;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}


/* 10.10.23 */

.modal.right.fade.in .modal-dialog {
    right: 0;
}

.form-control {
    font-size: 13px;
    font-weight: 400;
    font-family: 'Manrope', sans-serif;
}

input.form-control {
    height: 45px;
}

.member-form input {
    padding-left: 90px;
}

.member-form {
    position: relative;
}

.member-form .tags {
    position: absolute;
    top: 5px;
    left: 10px;
}

.btn-primary-blue {
    background-color: var(--light-blue-color);
    color: #fff;
}


/* 11.12.23 */

.idea-stats-block img {
    border-radius: 0 !important;
    margin-bottom: 0.4rem;
}

.files-add-panel {
    transition: all 0.3s;
}

.files-add-panel.collapse-panel {
    display: none;
}


/* 12.10.23 */

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }

    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }

    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }

    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }

    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }

    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }

    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}

.notification-dropdown span {
    width: 8px;
    height: 8px;
    background-color: #c79211;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: inline-flex;
    position: absolute;
    right: 0;
    top: 0;
    border: 5px solid #ef5350;
    /* animation: pulse 1s ease-out infinite; */
}

.notification-dropdown .dropdown-menu {
    background-color: #fff;
    height: calc((100vh - 80px) - 8px);
    margin-top: 80px;
    min-width: 360px;
    max-width: 375px;
    box-shadow: rgba(0, 0, 0, 0.1) -3px 4px 14px 0px;
    top: 0px;
    transition: top 100ms linear 0s;
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
    position: fixed;
}

.notification-dropdown .dropdown-toggle::after {
    display: none;
}

.notification-dropdown .dropdown-menu hr {
    border-color: var(--secondary-color);
}

.notification-body {
    padding: 0 14px 14px 14px;
    clear: both;
    height: calc(100% - 60px);
    overflow: hidden auto;
    word-break: break-word;
    display: flex;
    flex-direction: column;
}

.mark-text span {
    font-size: 13px;
    color: var(--secondary-color);
    font-size: 13px;
}

.notification-body-text {
    display: flex;
    justify-content: space-between;
}

.notification-body-pic {
    margin-right: 12px;
    margin-top: 5px;
}

.notification-body-inner-text {
    margin-right: 20px;
}

.notification-body-inner-text span {
    font-size: 12px;
    color: #74788D;
    font-weight: 400;
}

.notification-body-unread-dot span {
    width: 10px;
    height: 10px;
    background-color: var(--light-blue-color);
    border-radius: 50px;
    display: inline-block;
}

.notification-body-inner-text a {
    display: block;
    font-size: 12px;
    color: var(--light-blue-color);
    margin: 10px 0 0 0;
    text-decoration: none;
}

.notification-body-inner-text a span {
    width: 20px;
    height: 20px;
    background-color: var(--light-blue-color);
    border-radius: 50px;
    display: inline-block;
    font-size: 8px;
    margin-right: 5px;
    color: #fff;
    text-align: center;
    line-height: 22px;
}

.unread-notify {
    margin: 0;
}


/* 13.10.23-dashboard */

.executive-dashboard {
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 12px 24px 0px rgba(18, 38, 63, 0.02);
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.table-design .bs-table-body-row{
   border-bottom: 1px solid #E1DFF0;
   margin-top: 0;
   padding: 0;
   
}
.table-design .bs-table-body-row > .col-md-4, .table-design .bs-table-body-row > .col-md-3{
    border-right: 1px solid #E1DFF0;
    padding: 15px !important;
    min-height: 55px;
}
.table-design .balance-sheet-table-header{
    padding: 15px !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.table-design .balance-sheet-table-body {
    max-height: calc(100vh - 400px);
    overflow: auto;
}
.table-design .balance-sheet-table-header {
    padding: 0 !important;
}
.table-design .balance-sheet-table-header > .col-md-4, .table-design .balance-sheet-table-header > .col-md-3{
    /* border-right: 1px solid #646464; */
    padding: 15px !important;
    
}

/*-------17-05-24------*/
.steps-panel-a {
color: #495057;
}
.steps-panel-wrapper{
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.steps-panel{
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.steps-panel .step-block{
    position: relative;
    flex-grow: 1;
    z-index: 1;
}
.steps-panel .step-block > a{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.steps-panel .step-block span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #fff;
    border: 4px solid #dfdfdf;
    font-size: 18px;
    font-weight: 600;
}
.steps-panel .step-block.active span{
    border: 4px solid #cf9821;
}
.steps-panel .step-block.completed span{
    font-size: 0;
    background: url("../images/progress-check.svg") no-repeat 50% 50% #fff;
    background-size: 80%;
    border: 4px solid #cf9821;
}
.steps-panel .step-block::before{
    content: '';
    height: 4px;
    background: #dfdfdf;
    display: block;
    position: absolute;
    top: 23px;
    left: 50%;
    /* right: -50%; */
    right: calc(-50% - 24px);
    z-index: -1;
}
.steps-panel .step-block.completed::after{
    content: '';
    height: 4px;
    background: #cf9821;
    display: block;
    position: absolute;
    top: 23px;
    left: 50%;
    /* right: -50%; */
    right: calc(-50% - 24px);
    z-index: -1;
}
.steps-panel .step-block h4{
    font-size: 16px;
    font-weight: 600;
}
.steps-panel .step-block:last-child:before,
.steps-panel .step-block:last-child:after{
    display: none;
}

.steps-panel .step-block > .step-block-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.steps-panel .step-block > .step-block-inner span a {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*-------17-05-24------*/


.hang-num {
    width: 96px;
    height: 96px;
    background: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 35px;
    border-radius: 5rem;
    font-weight: 300;
}

.input-group-custom input.form-control {
    height: 38px;
}

.dashboard-top-part-body {
    padding: 10px 15px;
    border-radius: 6px;
    border: 1px dashed #B1C2E9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
}

.dashboard-top-part-body:hover {
    transition: 0.3s ease-in-out;
    transform: translateY(-5px);
}

.mx-28 {
    margin-top: 28px;
    margin-bottom: 28px;
}

.dashboard-bottom-part-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 9px;
    position: relative;
}

.dashboard-bottom-part-body::after {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--light-blue-color);
    width: 105px;
    height: 2px;
    content: "";
}

.dashboard-bottom-part-body span {
    font-size: 14px;
    font-weight: 600;
    color: #393E5E;
    text-transform: uppercase;
}

.dashboard-bottom-part-body .number-avatar {
    width: 42px;
    height: 42px;
    font-size: 20px;
}

.graph-text span {
    font-size: 13px;
    font-weight: 500;
    color: #393E5E;
    display: block;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.graph-text {
    display: flex;
    align-items: center;
    gap: 15px;
    text-align: center;
    margin: 20px 0 15px 0;
    justify-content: space-between;
}

.graph-text div {
    text-align: center;
}

.number-avatar-purple {
    background-color: #9723AC;
}

.number-avatar-red {
    background-color: var(--primary-color);
}

.flight-section {
    margin: 40px 60px;
}

.flight-section ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.flight-section ul li {
    list-style: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 25%;
    align-items: center;
}

.flight-section ul li::after {
    width: 70%;
    height: 1px;
    background-color: #AFAFAF;
    right: -136px !important;
    position: absolute;
    content: "";
    top: 42px;
    z-index: 0;
}

.flight-section ul li:last-child::after {
    width: 20%;
    height: 1px;
    background-color: #AFAFAF;
    right: 50px !important;
    position: absolute;
    content: "";
    top: 42px;
    z-index: 0;
}

.flight-section ul li:last-child::before {
    background: url(../images/arrow-right.png) center right 40px no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    background-position-y: 36px;
}

.flight-section ul li span {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin: 18px 0 0 0;
    display: block;
    text-transform: capitalize;
}

.flight-section ul li img {
    height: 72px;
    background-color: #fff;
    z-index: 1;
}

.flight-take-information-body {
    padding: 20px 25px;
}

.flight-take-information-body h2 {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin: 0 0 40px 0;
    text-align: left;
    text-transform: uppercase;
    height: 24px;
}

.flight-take-black-col {
    background-color: #222747;
}

.flight-take-black-col-two {
    background-color: #2b3154;
}

.flight-take-information {
    border-radius: 6px;
    overflow: hidden;
}
.main-container {
    min-height: calc(100vh - 210px);
}

.flight-take-information .row {
    margin-left: 0;
    margin-right: 0;
}

.flight-take-information-body ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.flight-take-information-body ul li {
    list-style: none;
    margin-bottom: 15px;
    padding: 16px 18px;
    background-color: #fff;
    border-radius: 10px;
    color: #393E5E;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
}

.flight-take-information-body ul li .number-avatar {
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: 600;
}

.flight-take-black-col-two .number-avatar {
    background-color: #E7ECF9;
    color: #000;
}

.flight-take-white-col-avatar .number-avatar {
    background-color: #fff;
    color: #000;
}

.desk-none {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}


/* 18.10.23(use-case-library) */

.project-sub-hed {
    color: #495057;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.pt-34 {
    padding-top: 34px;
}

.feature-date {
    padding: 5px 0 0 0;
    display: flex;
}

.feature-date h5 {
    font-size: 13px;
    color: #76808B;
    font-weight: 500;
    margin: 0 8px 0 0;
    padding-right: 8px;
    border-right: 1px solid #76808B;
}

.feature-date h5 span {
    font-size: 13px;
    color: #15181B;
    font-weight: 500;
}

.feature-date h5:last-child {
    border-right: none;
}

.pt-25 {
    padding-top: 25px;
}

.feature-graphis-icon {
    display: flex;
}

.feature-icon {
    margin-right: 15px;
}

.feature-icon-text span {
    color: #76808B;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
}

.feature-icon-text h5 {
    color: #15181B;
    font-size: 13px;
    font-weight: 500;
    margin: 0;
}

.feature-graphis-line-icon .feature-graphis-icon {
    padding: 35px 25px;
}

.feature-graphis-border {
    border-bottom: 1px solid #D8D8D8;
}

.feature-graphis-border-right {
    border-right: 1px solid #D8D8D8;
}

.feature-graphis-line-icon {
    margin: 20px 0 0 0;
}

.mx-15 {
    margin-left: 15px;
    margin-right: 15px;
}

.btn-secondary {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #000;
    border-radius: 5px;
    font-size: 13px;
}

.btn-secondary:hover {
    background-color: #cacaca;
    border-color: #cacaca;
    color: #000;
}

.flight-section-gray span {
    color: #AFB8C2 !important;
}

.flight-section-gray .active span {
    color: #000 !important;
}

.usecase-paragraph .form-area label {
    color: #222747;
    margin-bottom: 16px;
}

.usecase-paragraph .form-area p {
    color: #5C617C;
    font-size: 13px;
    margin: 0;
}

.mb-50 {
    margin-bottom: 50px;
}

.flight-section-gray {
    margin: 70px 0 50px 0 !important;
}

.question-checkbox {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.question-checkbox li {
    display: flex;
    list-style: none;
    font-size: 13px;
    font-weight: 500;
    color: #5C617C;
    margin-right: 20px;
    align-items: center;
}

.question-checkbox .q-check-box {
    background: url(../images/demo-check.svg) no-repeat 0 0;
    width: 18px;
    height: 18px;
    background-size: cover;
    margin-right: 5px;
    display: inline-block;
}

.question-checkbox .active .q-check-box {
    background: url(../images/demo-check-active.svg) no-repeat 0 0;
    width: 18px;
    height: 18px;
    background-size: cover;
    margin-right: 5px;
    display: inline-block;
}


/*-------------------------------- Style added Css Start ------------------------------*/

.borderradius_startdate {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.borderradius_enddate {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dashboard-top-part-body h3 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    color: #000;
    padding: 0 0 15px 0;
    margin: 0;
}

.dashboard-topgraph {
    display: flex;
}

.dashboard-topgraphleft p {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    padding: 0;
    margin: 0;
}

.kpidashboard_top .dashboard-top-part-body {
    min-height: inherit !important;
}

.dashboard-topPrice {
    color: var(--primary-color);
    font-family: 'Oswald', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.dashboard-topgraphleft {
    flex-shrink: 0;
}

.form-check-input {
    clear: left;
}

.form-switch.form-switch-sm {
    margin-bottom: 0.5rem;
    /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
    height: 1rem;
    width: calc(1rem + 0.75rem);
    border-radius: 2rem;
}

.form-switch.form-switch-md {
    margin-bottom: 1rem;
    /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-md .form-check-input {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
}

.form-switch.form-switch-lg {
    margin-bottom: 1.5rem;
    /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
    height: 2rem;
    width: calc(3rem + 0.75rem);
    border-radius: 4rem;
}

.form-switch.form-switch-xl {
    margin-bottom: 2rem;
    /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
    height: 2.5rem;
    width: calc(4rem + 0.75rem);
    border-radius: 5rem;
}

.priod_toggle .form-check-input:checked {
    background-color: #8BB7F0 !important;
    border-color: #8BB7F0 !important;
}

.form-check-input:focus {
    box-shadow: none !important;
}

.preiod_txt {
    color: #393E5E;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
}

.preiod_txt span {
    font-weight: 700;
}

.priod_table {
    padding: 0;
    margin: 0;
}

.priod_table th,
.priod_table td {
    font-size: 12px;
    line-height: 18px;
    padding: 8px 5px !important;
}

.priod_table th {
    background: #f5f8ff;
    border-bottom: dashed 1px #B1C2E9;
}

.priod_table tr:nth-child(odd) td {
    background: #f5f5f5;
    font-weight: 600;
}

.priod_table tr:last-child td {
    background: #f5f8ff;
    font-weight: 600;
    font-size: 13px;
    padding: 15px 5px !important;
}

.go_nogonav .active.like {
    border-radius: 5px 0 0 5px;
}

.go_nogonav .active.reject {
    border-radius: 0px 5px 5px 0px;
}

.go_nogonav {
    background: #EEF1F9;
}

.go_nogonav i {
    color: #fff;
}

.go_nogonav .active i {
    color: #5c5c5c;
}

.height-auto {
    height: auto;
}

.btn-nrowse {
    background: var(--secondary-color);
    color: #fff;
}

.testcase_right h5 {
    padding: 0 0 5px 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
}

.testcase_right p {
    padding: 0;
    margin: 0;
    font-size: 0.75rem;
    font-weight: 400;
}

.testcase_listtxt ol li {
    padding: 0;
    margin: 0;
    font-size: 0.75rem;
    font-weight: 400;
}

.category_title {
    padding: 0.75rem;
    font-size: 13px;
    font-weight: 600;
    background: #f6f6f6;
    border-bottom: 1px solid var(--primary-color);
}

.category_subaccordian .card-header {
    padding: 0;
}

.category_subaccordian .action-btns .edit,
.category_subaccordian .action-btns .delete {
    width: 24px;
    height: 24px;
    font-size: 10px;
}

.category_subaccordian .action-btns {
    padding: 5px 0;
}

.category_subaccordian h2 button.accordion-button {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-color);
    text-decoration: none;
    padding: 0.47rem 0.75rem;
    background: none;
    box-shadow: none;
}

.category_subaccordian h2 button.accordion-button:after {
    display: none;
}

.category_subaccordian .custom-table td {
    padding: 5px 10px;
}

.category_subaccordian .custom-table th:first-child,
.category_subaccordian .custom-table td:first-child {
    width: 76.5%;
    text-align: left;
}

.btn:focus {
    box-shadow: none !important;
}

.add_category_btn {
    padding: 3px 8px;
    background: var(--primary-color);
    color: #fff;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
}

.add_category_btn:hover {
    color: #fff;
}

.left-panel {
    flex-shrink: 0;
}

.hanger_decisionprosess .progress-steps {
    padding: 0.5rem 0;
}

.hangerdesicion_content ul {
    min-height: 50vh;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hangerdesicion_content ul li {
    padding: 0;
    margin: 0 10px;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    max-width: calc(33.3333% - 20px);
}

.hangerdesicion_content ul li .hangerdesicionbox {
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 17px;
    background: #34C38F;
}

.hangerdesicion_content ul li:nth-child(2) .hangerdesicionbox {
    background: #F98D2A;
}

.hangerdesicion_content ul li:nth-child(3) .hangerdesicionbox {
    background: #F65656;
}

.hangerdesicion_content ul li .hangerdesicionbox_circel {
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    background: #fff;
}

.hangerdesicion_content ul li .hangerdesicionbox_circel i {
    font-size: 55px;
}

.hangerdesicion_content ul li h5 {
    color: #4F4F4F;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding: 10px 0 0 0;
    margin: 0;
}

.text-left {
    text-align: left !important;
}

.custom-file-uploader.w-100 {
    max-width: inherit;
    width: 100%;
}

.badge-secondary {
    background: var(--primary-color);
}

label sup {
    color: var(--primary-color);
}

.idea-stats-block h6.text-sm.text-white {
    font-size: 12px;
}

.w-35 {
    width: 35% !important;
}


/*-------------------------------- Style added Css End ------------------------------*/

.circel {
    width: 24px;
    height: 24px;
    border-radius: 500px;
    background: #b32f84;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    flex-shrink: 0;
}

.overview_box {
    padding: 15px;
    border: 1px dashed #AFAFAF;
    border-radius: 10px;
}

.m-left40 {
    margin-left: 40px;
}

h5.sub_title {
    font-size: 0.9rem;
    padding: 0;
    margin: 0 0 12px 0;
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .flight-section ul li::after {
        right: -106px !important;
    }
}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 95%;
    }

    .graph-text span {
        font-size: 11px;
    }

    .flight-section {
        margin: 40px 40px;
    }

    .flight-section ul li::after {
        right: -106px;
    }

    .flight-take-information-body h2 {
        font-size: 18px;
        margin: 0 0 30px 0;
    }

    .flight-take-information-body ul li .number-avatar {
        font-size: 16px;
    }
}

@media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 95%;
    }

    .graph-text span {
        font-size: 11px;
    }

    .flight-section {
        margin: 40px 40px;
    }

    .flight-section ul li::after {
        right: -106px;
    }
}

@media only screen and (width:768px) {
    .desk-none {
        display: block;
    }

    .resp-none {
        display: none;
    }

    .right-float-bar {
        transform: translateX(800px);
    }

    /* 18.10.23 */
    .resp-border {
        border: 1px solid #D8D8D8;
    }

    .feature-graphis-line-icon .mx-15 {
        margin-left: 0;
        margin-right: 0;
    }

    .feature-graphis-line-icon .btn {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .usecase-paragraph.mb-50 {
        margin-bottom: 30px;
    }

    .feature-graphis-line-icon .feature-graphis-icon {
        padding: 25px 20px !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .executive-dashboard {
        padding: 15px;
    }

    .dashboard-top-part-body .h5 {
        font-size: 1rem;
    }

    .dashboard-bottom-part-body span {
        font-size: 12px;
    }

    .graph-text span {
        font-size: 11px;
    }

    .hang-num {
        width: 70px;
        height: 70px;
        font-size: 20px;
    }

    .dashboard-bottom-part-body .number-avatar {
        width: 30px;
        height: 30px;
        font-size: 12px;
    }

    .graph-text {
        margin: 8px 0 8px 0;
    }

    .dashboard-top-part-body {
        min-height: 205px;
    }

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 95%;
    }

    .flight-section {
        margin: 30px 30px;
    }

    .flight-section ul li img {
        height: 50px;
    }

    .flight-section ul li span {
        font-size: 15px;
    }

    .flight-section ul li::after {
        width: 100%;
        top: 30px;
    }

    .flight-section ul li:last-child::after {
        top: 30px;
    }

    .flight-section ul li:last-child::before {
        background-position-y: 24px;
    }

    .flight-take-information-body h2 {
        font-size: 14px;
        margin: 0 0 20px 0;
    }

    .flight-take-information-body ul li {
        margin-bottom: 10px;
        padding: 10px 10px;
        font-size: 11px;
    }

    .flight-take-information-body ul li .number-avatar {
        width: 30px;
        height: 30px;
        font-size: 13px;
    }

    /* 18.10.23 */
    .feature-graphis-line-icon .feature-graphis-icon {
        padding: 15px 15px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .hangerdesicion_content ul li .hangerdesicionbox {
        width: 120px;
        height: 120px;
    }

    .hangerdesicion_content ul li .hangerdesicionbox_circel {
        width: 70px;
        height: 70px;
    }

    .hangerdesicion_content ul li .hangerdesicionbox_circel i {
        font-size: 40px;
    }

    .hangerdesicion_content ul li h5 {
        font-size: 12px;
        min-height: 54px;
    }

    .hanger_decisionprosess .progress-steps {
        flex-direction: column;
    }

    .progress-steps p {
        min-height: 30px;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
    .executive-dashboard {
        padding: 15px;
    }

    .dashboard-top-part-body .h5 {
        font-size: 1rem;
    }

    .dashboard-bottom-part-body span {
        font-size: 12px;
    }

    .graph-text span {
        font-size: 11px;
    }

    .hang-num {
        width: 70px;
        height: 70px;
        font-size: 20px;
    }

    .dashboard-bottom-part-body .number-avatar {
        width: 30px;
        height: 30px;
        font-size: 12px;
    }

    .graph-text {
        margin: 8px 0 8px 0;
    }

    .dashboard-top-part-body {
        min-height: 205px;
    }

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 95%;
    }

    .flight-section {
        margin: 30px 30px;
    }

    .flight-section ul li img {
        height: 50px;
    }

    .flight-section ul li span {
        font-size: 15px;
    }

    .flight-section ul li::after {
        width: 100%;
        top: 30px;
    }

    .flight-section ul li:last-child::after {
        top: 30px;
    }

    .flight-section ul li:last-child::before {
        background-position-y: 24px;
    }

    .flight-take-information-body h2 {
        font-size: 14px;
        margin: 0 0 20px 0;
    }

    .flight-take-information-body ul li {
        margin-bottom: 10px;
        padding: 10px 10px;
        font-size: 11px;
    }

    .flight-take-information-body ul li .number-avatar {
        width: 30px;
        height: 30px;
        font-size: 13px;
    }

    .desk-none {
        display: block;
    }

    .resp-none {
        display: none;
    }

    .dashboard-top-part-body {
        margin-bottom: 15px;
    }
}

@media (max-width:814px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 95%;
    }

    .left-panel {
        width: 5px;
    }

    .left-panel .accordion {
        opacity: 0;
        overflow: hidden;
    }

    .left-panel.collapse-panel {
        width: auto;
    }

    .left-panel.collapse-panel .accordion {
        opacity: 1;
        overflow: visible;
    }

    .for-mob {
        display: flex !important;
    }

    .navbar-toggler {
        display: none;
    }

    .navbar-brand img {
        max-width: 120px;
    }

    .three-col-block {
        width: 100%;
        margin-right: 0;
    }

    .two-panel-wrapper {
        flex-direction: column;
    }

    .flex-col-mob {
        flex-direction: column !important;
    }

    .flex-col-mob .w-f-125,
    .flex-col-mob .w-f-200 {
        width: 100%;
    }

    .flex-col-mob.align-items-center {
        align-items: flex-start !important;
    }

    .table-filter-panel {
        flex-wrap: wrap;
    }

    .table-filter-panel>div {
        flex: auto;
        width: 45%;
    }

    .table-filter-panel>div:last-child {
        width: 100%;
    }

    .date-range-flds input {
        flex: 1;
    }

    .main-container {
        padding-bottom: 15px;
    }

    .mob-menu-wrapper {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--secondary-color);
        border-radius: 15px 15px 0 0;
        z-index: 99;
    }

    .mob-menu {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .mob-menu li {
        flex: 1;
        font-size: 12px;
    }

    .mob-menu li a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.8rem 0.5rem;
        color: rgba(255, 255, 255, 0.8);
        border-radius: 15px 15px 0 0;
    }

    .mob-menu li.active a {
        background: var(--primary-color);
    }

    .left-panel {
        position: fixed;
        left: 0;
        z-index: 99;
        top: 160px;
        bottom: 120px;
    }

    .progress-steps {
        flex-direction: column;
    }

    .progress-steps {
        padding: 1rem 0.3rem;
        justify-content: flex-start;
    }

    .progress-steps p {
        font-size: 10px;
    }

    .table-responsive {
        max-width: 86vw;
    }

    .resp-table tbody tr td p {
        white-space: nowrap;
    }

    .resp-table .table-pic img {
        max-width: none;
    }

    .right-float-bar {
        width: 100%;
    }

    .list-table {
        margin-bottom: 10px;
    }

    .right-float-bar.collapse-panel-right {
        width: 100%;
    }

    .right-float-bar {
        top: 60px;
    }

    .notification-dropdown .dropdown-menu {
        margin-top: 65px;
    }

    .executive-dashboard {
        padding: 20px;
    }

    .dashboard-top-part-body {
        margin-bottom: 15px;
    }

    .flight-section {
        display: none;
        margin: 0px 0 15px 0;
    }

    .flight-section ul {
        display: block;
        text-align: center;
    }

    .flight-section ul li {
        width: 44%;
        display: inline-block;
        margin: 7px 7px;
        padding: 15px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        border: 1px dashed #B1C2E9;
        border-radius: 6px;
    }

    .flight-section ul li img {
        height: 50px;
    }

    .flight-section ul li span {
        font-size: 15px;
    }

    .flight-section ul li::after {
        display: none;
    }

    .flight-take-information-body h2 {
        font-size: 18px;
        margin: 0 0 18px 0;
        height: auto;
    }

    .flight-take-information-body ul li .number-avatar {
        font-size: 16px;
    }

    .flight-section ul li:last-child::before {
        display: none;
    }

    .right-float-bar-body {
        height: calc(100vh - 150px);
    }

    /* 18.10.23 */
    .resp-border {
        border: 1px solid #D8D8D8;
    }

    .feature-graphis-line-icon .mx-15 {
        margin-left: 0;
        margin-right: 0;
    }

    .feature-graphis-line-icon .btn {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .usecase-paragraph.mb-50 {
        margin-bottom: 30px;
    }

    .feature-graphis-line-icon .feature-graphis-icon {
        padding: 25px 20px !important;
    }

    .feature-image-text.pt-34 {
        padding-top: 24px;
    }

    .feature-date {
        padding: 15px 0 0 0;
    }

    .question-checkbox {
        display: block;
    }

    .question-checkbox li {
        margin: 10px 0;
    }

    /*-------------------------------- Style added Css Start ------------------------------*/
    .kpidashboard_top .dashboard-top-part-body {
        min-width: calc(50% - 16px);
    }

    .hangerdesicion_content ul {
        flex-direction: column;
    }

    /* .hangerdesicion_content ul li .hangerdesicionbox{
      width:80px;
      height:80px;
    }
    .hangerdesicion_content ul li .hangerdesicionbox_circel{
      width:55px;
      height:55px;
    }
    .hangerdesicion_content ul li .hangerdesicionbox_circel i{
      font-size: 35px;
    } */
    /*-------------------------------- Style added Css End ------------------------------*/
}


/*-------------------------------- Style added Css Start ------------------------------*/

@media (max-width:767px) {
    .kpidashboard_top .dashboard-top-part-body {
        min-width: 100%;
    }

    .add_category_btn.ms-auto {
        margin-left: 10px !important;
    }

    .hangerdesicion_content ul {
        flex-direction: column;
    }

    .hangerdesicion_content ul li {
        margin-bottom: 30px;
    }

    .hangerdesicion_content ul li .hangerdesicionbox {
        width: 120px;
        height: 120px;
    }

    .hangerdesicion_content ul li .hangerdesicionbox_circel {
        width: 80px;
        height: 80px;
    }

    .hangerdesicion_content ul li .hangerdesicionbox_circel i {
        font-size: 40px;
    }
}


/*-------------------------------- Style added Css End ------------------------------*/

.dashboard-menu-panel {
    background: var(--secondary-color) no-repeat 0 0;
    position: fixed;
    left: 0;
    width: 200px;
    bottom: 69px;
    border-radius: 15px;
    padding: 20px 15px;
    z-index: 99;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    transform: translateY(500px);
    transition: all 0.3s ease-in;
}

.dashboard-menu-panel.show-menu {
    transform: translateY(0);
}

.dashboard-menu-panel>ul {
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;
}

.dashboard-menu-panel>ul li {
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.dashboard-menu-panel>ul li a {
    color: white;
}

.role-permission-toggler-wrapper .form-check {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
}

.role-permission-toggler-wrapper .form-check>.form-check-label {
    width: 170px;
}

.role-permission-toggler .form-check span {
    font-weight: 600;
    position: absolute;
    left: -18px;
    top: 5px;
    display: block;
    text-align: right;
    width: 14px;
}

.role-permission-toggler .form-switch .form-check-input {
    width: 3rem;
    height: 1.5rem;
}

@media(max-width: 1400px) {
    .role-permission-toggler-wrapper .form-check>.form-check-label {
        width: 120px;
    }
}

@media(max-width: 914px) {
    .role-permission-toggler-wrapper>.col-sm-4>.d-flex {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 10px !important;
    }

    .role-permission-toggler {
        margin-left: 15px;
    }

    .role-permission-toggler-wrapper>.col-sm-4 {
        border-bottom: 1px solid #dfdfdf;
        padding-bottom: 12px;
        margin-bottom: 12px !important;
    }

    .role-permission-toggler-wrapper>.col-sm-4:last-child {
        border: none;
        margin-bottom: 0 !important;
    }
}


/* NEW CSS */

.form-group {
    margin: 0 0 15px;
}

.form-group label {
    display: flex;
    width: 100%;
    color: #00205c;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 5px;
}

.form-group .form-control {
    border-radius: 8px;
    /* background: #F2F1F9; */
    height: 39px;
}

.forgot-link {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 0 0 15px;
}

.forgot-link a,
.forgot-link button {
    margin: 0;
    display: inline-flex;
    text-decoration: none;
    color: #949494;
    text-align: right;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.btn-solid {
    border-radius: 8px;
    background: #00205c;
    color: #FFF;
    text-align: right;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.btn-outline {
    color: #00205c;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid #00205c;
    background: #FFF;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.or-wrap {
    margin: 25px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.or-wrap::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #E9EFFB;
    position: absolute;
    left: 0;
    top: 9px;
}

.or-wrap span {
    color: #949494;
    text-align: right;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #fff;
    padding: 0 5px;
    position: relative;
    z-index: 1;
}

.login-footer {
    margin: 0px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
}

.login-footer p {
    color: #757575;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 15px 0;
}

.upload-logo-wrap {
    color: #FFF;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn {
    border: 0 none;
    background-color: white;
    padding: 0;
    border-radius: 8px;
    color: #C79211;
    text-align: center;
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
    flex-direction: column;
}

.upload-btn img {
    width: 13px;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.two-col-wrap {
    display: flex;
    justify-content: space-between;
}

.left-bar-wrap {
    width: 250px;
    background: #E1E9F9;
    height: 100vh;
    position: fixed;
    left: 0;
    top: calc(70px + 56px);
    z-index: 1;
}

.left-bar-wrap ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.left-bar-wrap ul li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.left-bar-wrap ul li a {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    color: #8492AB;
    color: #8492AB;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-items: center;
}

.left-bar-wrap ul li a img {
    margin: 0 5px 0 0;
}

.left-bar-wrap ul li a.active,
.left-bar-wrap ul li a:hover {
    color: #000;
}

.left-bar-wrap ul li a:hover img,
.left-bar-wrap ul li a.active img {
    filter: brightness(0);
    -webkit-filter: brightness(0);
}

.right-side-wrap {
    margin: 0 0 0 250px;
    width: calc(100% - 250px);
    padding: 20px 15px 20px 30px;
}

.page-title {
    color: #000;
    font-family: Manrope;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.white-body-wrap {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
    padding: 35px;
}

.profile-box {
    margin: 0 0 25px;
    position: relative;
}

.profile-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px;
}

.profile-title::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.05);
}

.profile-title em {
    color: #C79211;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: inline-flex;
    background-color: #fff;
    padding: 0 15px 0 0;
    position: relative;
    z-index: 1;
}

.profile-title span {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: #00205c;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin: 0 30px 0 0;
    cursor: pointer;
}

.form-group-text {
    margin: 0 0 15px;
}

.form-group-text h4 {
    color: #6C6C6C;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 5px;
}

.form-group-text p {
    color: #000;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.upload-image-wrapper {
    position: relative;
    overflow: hidden;
    display: block;
}

.upload-image-btn {
    border: 0 none;
    padding: 0;
    border-radius: 10px;
    background: #D9D9D9;
    color: #A4A4A4;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
    flex-direction: column;
}

.upload-image img {
    width: 13px;
}

.upload-image-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.two-btn-wrap {
    display: inline-flex;
    border-radius: 10px;
    border: 1px solid #E2E6ED;
    background: #FFF;
    height: 50px;
}

.two-btn-wrap a {
    display: inline-flex;
    width: 50px;
    border-left: 1px solid #E2E6ED;
    align-items: center;
    justify-content: center;
}

.two-btn-wrap a img {
    max-width: 20px;
}

.new-dropdown {
    border-radius: 8px;
    background: #007bff;
}

.new-dropdown span {
    color: #FFF;
    text-align: right;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #ffffff2e;
}

.new-dropdown span img {
    margin: 0 10px 0 0;
}

.new-dropdown.dropdown-toggle::after {
    content: "";
    background: url(../images/drop-white.svg) left center no-repeat;
    width: 10px;
    height: 10px;
    border: 0 none;
    margin: 0 15px 0 5px;
}

.add-new-dropdown-box {
    border: 0 none;
    background-color: var(--primary-color) !important;
}

.add-new-dropdown-box .dropdown-item {
    font-size: 14px;
    color: #fff;
}

.add-new-dropdown-box .dropdown-item:hover {
    background-color: #00205c !important;
}

.table-top {
    margin: 0 0 15px;
}

.filter-box {
    margin: 0;
}

.filter-box .form-control {
    border-radius: 8px;
    background: #F2F1F9;
    color: #00205c;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.filter-box .search-form-control {
    background: #F2F1F9 url(../images/search-icon.svg) 10px 10px no-repeat;
    padding-left: 40px;
}

.filter-box .form-select {
    border-radius: 8px;
    background-color: #F2F1F9;
    color: #00205c;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 45px;
}

.filter-wrap {
    display: flex;
}

.filter-wrap .filter-box {
    /* width: 40%; */
    margin: 0 20px 0 0;
}

.filter-btn {
    margin: 0;
}

.filter-btn button,
.filter-btn a {
    border-radius: 8px;
    background: #007bff;
    color: #FFF;
    text-align: right;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 38px;
    border: 0 none;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
}

.filter-btn button:hover, .filter-btn a:hover {
    background: #0069d9;
}

.filter-btn .button-secondary {
    border-radius: 8px;
    background: #E6E6E6;
    color: #000;
    text-align: right;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 38px;
    border: 0 none;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
}

.filter-btn .button-secondary:hover {
    background: #cacaca;
}

.filter-btn .button-tertiary {
    border-radius: 8px;
    background: #212529;
    color: #fff;
    text-align: right;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 38px;
    border: 0 none;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
}

.filter-btn .button-tertiary:hover {
    background: #4c5053;
}

.filter-btn button img,
.filter-btn a img {
    margin: 0 8px 0 0;
}

.table-drop.dropdown-toggle {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #E2E6ED;
    background: #FFF;
}

.table-drop.dropdown-toggle>span {
    color: #00205c;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #E2E6ED;
    width: 100%;
}

.table-drop.dropdown-toggle>span img {
    margin: 0 10px 0 0;
}

.table-drop.dropdown-toggle>span strong {
    display: inline-flex;
    margin: 0 5px 0 0;
    color: #00205c;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.table-drop.dropdown-toggle.dropdown-toggle::after {
    content: "";
    background: url(../images/drop-black.svg) left center no-repeat;
    width: 20px;
    height: 20px;
    border: 0 none;
    margin: 0 15px 0 10px;
}

.table-dropdown-box {
    width: 100%;
    text-align: right;
    overflow: hidden;
}

.table-dropdown-box {
    padding: 0;
}

.table-dropdown-box li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.table-dropdown-box .dropdown-item {
    font-size: 14px;
    padding: 8px;
}

.info-box {
    border-radius: 10px;
    background: #F6F6F6;
    padding: 15px;
    margin: 0 0 15px;
}

.info-box p {
    color: #000;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 15px;
}

.info-box p strong {
    font-weight: 700;
}

.info-box p:last-child {
    margin: 0;
}

.form-group textarea.form-control {
    height: 120px;
    resize: none;
}

.container-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}


/* Hide the browser's default checkbox */

.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.container-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    box-shadow: 0 0 0 1px #556EE6;
}


/* On mouse-over, add a grey background color */

.container-checkbox:hover input~.checkmark {
    background-color: #fff;
}


/* When the checkbox is checked, add a blue background */

.container-checkbox input:checked~.checkmark {
    background-color: #556EE6;
    box-shadow: 0 0 0 1px #556EE6;
}


/* Create the checkmark/indicator (hidden when not checked) */

.container-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.container-checkbox input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.container-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.modal-body .container-checkbox .checkmark {
    top: 1px;
}

.modal-footer {
    border-top: 0 none;
    padding-top: 0;
}

.gi-account-width {
    width: 10% !important;
}

.table tr td {
    white-space: nowrap !important;
}

.dashboard-box {
    margin: 0;
    height: 100%;
}

.dashboard-box .accordion {
    height: 100%;
}

.dashboard-box .accordion-item {
    border: 0 none;
    border-radius: 10px;
    background: #00205c;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}

.dashboard-box .accordion-button {
    color: #fff;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    background-color: #00205c;
    border-bottom: 1px solid #b68400;
    box-shadow: none;
}

.dashboard-box .accordion-button img {
    max-width: 30px;
    margin: 0 10px 0 0;
}

.dashboard-box .accordion-collapse {
    /* height: calc(100% - 66px); */
    min-height: 316px;
}

.dashboard-box .accordion-body ul {
    margin: 0;
    list-style: none;
    padding: 0;
}

.dashboard-box .accordion-body ul li {
    margin: 0 0 15px;
    border-bottom: 1px solid #213862;
}

.dashboard-box .accordion-body ul li:last-child {
    border-bottom: none;
}

.dashboard-box .accordion-body ul li a {
    color: #FFF;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* background: url("/assets/images/arrow-right.svg") left 7px no-repeat; */
    display: flex;
    width: 100%;
    padding-left: 25px;
    background-size: 15px;
    position: relative;
    padding-bottom: 10px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.dashboard-box .accordion-body ul li a:hover {
    /* background: url("/assets/images/arrow-right.svg") 5px 7px no-repeat; */
    padding-left: 30px;
    background-size: 15px;
}

.dashboard-box .accordion-body ul li a::after {
    content: "";
    position: absolute;
    right: -20px;
    bottom: -5px;
    background: rgba(0, 0, 0, 0.05);
    width: calc(100% + 20px);
    height: 1px;
}

.dashboard-box .accordion-body ul li:last-child a {
    padding-bottom: 0;
}

.dashboard-box .accordion-body ul li:last-child a::after {
    display: none;
}

.three-icon-wrap {
    border-radius: 8px;
    border: 1px solid #E2E6ED;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.three-icon-wrap a,
.three-icon-wrap button {
    border: 0 none;
    background-color: #fff;
    height: 100%;
    padding: 0 15px;
}

.three-icon-wrap a:nth-of-type(2),
.three-icon-wrap button:nth-of-type(2) {
    border-left: 1px solid #E2E6ED;
    border-right: 1px solid #E2E6ED;
}

.filter-box-top {
    margin: 0 0 20px;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
    padding: 20px;
}

.filter-box .add-icon {
    background: #F2F1F9 url(../images/circle-plus-icon.svg) right 10px center no-repeat;
}

.generate-btn {
    background: #007bff url(../images/generate-report-icon.svg) right 15px center no-repeat;
    background-size: 25px;
    border-radius: 8px;
    color: #FFF;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    border: 0 none;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px;
    max-width: 200px;
}

.generate-btn:hover {
    background-color: #0069d9;
}

/*


.balance-sheet-table {
    margin: 0;
}

.balance-sheet-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #0ED46B;
    padding: 5px 15px;
}

.balance-sheet-table-header h2 {
    color: #000;
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.balance-sheet-table-header h2:nth-of-type(1) {
    min-width: 70%;
}

.balance-sheet-table-header h2:nth-of-type(2) {
    min-width: 15%;
    text-align: right;
}

.balance-sheet-table-header h2:nth-of-type(3) {
    min-width: 15%;
    text-align: right;
}

.balance-sheet-table-body {
    padding: 0 15px;
    border-bottom: 1px solid;
    padding-bottom: 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.bs-sub-heading-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0;
}

.bs-sub-heading-row h3 {
    color: #000;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 0;
}

.bs-sub-heading-row h3:nth-of-type(1) {
    min-width: 70%;
}

.bs-sub-heading-row h3:nth-of-type(2) {
    min-width: 15%;
    text-align: right;
}

.bs-sub-heading-row h3:nth-of-type(3) {
    min-width: 15%;
    text-align: right;
}

.bs-table-body-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0;
}

.bs-table-body-row h4 {
    color: #000;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.bs-table-body-row h4:nth-of-type(1) {
    min-width: 70%;
}

.bs-table-body-row h4:nth-of-type(2) {
    min-width: 15%;
    text-align: right;
}

.bs-table-body-row h4:nth-of-type(3) {
    min-width: 15%;
    text-align: right;
}

.balance-sheet-table-body.no-border {
    border: 0 none;
}

.main-total-row {
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.main-total-row h5 {
    color: #000;
    text-align: left;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0;
}

.main-total-row h5:nth-of-type(1) {
    min-width: 70%;
}

.main-total-row h5:nth-of-type(2) {
    min-width: 15%;
    text-align: right;
}

.main-total-row h5:nth-of-type(3) {
    min-width: 15%;
    text-align: right;
}
*/

.balance-sheet-table {
    margin: 0;
}

.balance-sheet-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f2f1f9;
    padding: 3px 0px;
    /* position: sticky;
    top: 0;
    background-color: lightblue;
    padding: 10px; */
}



.balance-sheet-table-header h2 {
    color: #000;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.balance-sheet-table-header h2:nth-of-type(1) {
    min-width: 30%;
}

.balance-sheet-table-body {
    padding: 0 0px;
    border-bottom: 1px solid;
    padding-bottom: 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);

    /* height: 2000px;
    background-color: #f0f0f0;
    padding: 20px; */
}

.bs-table-body-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0;
    /* border-bottom: 0.1px solid darkgrey; */
    padding: 3px 0px;
}

.bs-table-body-row h4:nth-of-type(1) {
    min-width: 30%;
}

.SST-sum {
    border-top: 1px solid #79870c;
    white-space: nowrap;
}
.ST-sum {
    border-top: 1px solid #ad732c;
    white-space: nowrap;
}
.GT-sum {
    border-top: 1px solid #0ed46b;
    border-bottom: 1px solid #0ed46b;
    white-space: nowrap;
}

.bs-table-body-row h4:nth-of-type(2) {
    min-width: 15%;
    text-align: right;
}

.bs-table-body-row h4:nth-of-type(3) {
    min-width: 15%;
    text-align: right;
}

.bs-table-body-GH {
}
.bs-table-body-GH h4 {
    color: #000;
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.bs-table-body-SH {
    
}
.bs-table-body-SH h4 {
    color: #000;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.bs-table-body-SSH {
   
}
.bs-table-body-SSH h4 {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.bs-table-body-VP {
    
}
.bs-table-body-VP h4 {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
}

.bs-table-body-VP\/IP h4 {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
}

.bs-table-body-IP h4 {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
}

.bs-table-body-SST {
    
}
.bs-table-body-SST h4 {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.bs-table-body-ST {
    
}
.bs-table-body-ST h4 {
    color: #000;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.bs-table-body-GT {
    
}
.bs-table-body-GT h4 {
    color: #000;
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.bs-table-body-GT.resize-font h4 {
    color: #000;
    font-family: Manrope;
    /* font-size: 22px; */
    font-size: clamp(0.8rem, 2vw + 1rem, 1.1rem);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    white-space: nowrap;
}

.bs-table-body-H {
   
}
.bs-table-body-H h4 {
    color: #000;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.bs-table-body-TT {
   
}
.bs-table-body-TT h4 {
    color: #000;
    /* background-color: #000; */
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    /* padding: 10px; */
}

.bs-table-body-CT {
   
}
.bs-table-body-CT h4 {
    /* background-color: #000; */
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    /* padding: 10px; */
    color: #000;
}



.bs-table-body-RGT {
   
}
.bs-table-body-RGT h4 {
    /* background-color: #000; */
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    color: #000;
    /* padding: 10px; */
}

.ration-amount {
   
}
.ration-amount h4 {
    color: #ad732c;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.sub-data{
    font-weight : 200;
}
.under-line{
    text-decoration: underline;
}



.loading {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 99;
}
.position-fix{
    padding: 0px 225px !important;
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .dashboard-box .accordion-button {
        font-size: 16px;
    }

    .dashboard-box .accordion-body ul li a {
        font-size: 13px;
        /* background: url(/assets/images/arrow-right.svg) left 5px no-repeat; */
        padding-left: 20px;
        background-size: 10px;
    }
}

/*----------------------09-02--------------------*/
.module-permission-table-row.th {
    border-top: 1px solid #DBDEE7;
    border-bottom: 1px solid #DBDEE7;
    color: #43495C;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.module-permission-table-row.th .module-permission-col:first-child,
.module-permission-table-row.td .module-permission-col:first-child {
    /* padding-left: 80px; */
    padding-left: 20px;
    text-align: left;
}

.cloapasableSettings .module-permission-table-row.td .module-permission-col:first-child {
    padding-left: 100px;
}

.module-permission-table-row.td {
    border-bottom: 1px solid #DBDEE7;
}

.module-permission-col {
    width: 25%;
    flex-shrink: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.module-permission-col:first-child {
    flex: 1;
}

.module-permission-table-row.th .module-permission-col:first-child,
.module-permission-table-row.td .module-permission-col:first-child {
    /* padding-left: 80px; */
    padding-left: 20px;
    text-align: left;
}

.role_multiclopasable .module-permission-table-row.td .module-permission-col[aria-expanded="false"] {
    background: url(../images/plus.png) no-repeat 50px center;
    background-size: 25px 25px;
    line-height: 28px;
}

.module-permission-table-row.td .module-permission-col[aria-expanded="true"] {
    background: url(../images/minus.png) no-repeat 50px center;
    background-size: 25px 25px;
    line-height: 28px;
}

.cloapasableSettings .module-permission-table-row.td .module-permission-col[aria-expanded="false"] {
    background: url(../images/plus.png) no-repeat 70px center;
    background-size: 25px 25px;
    line-height: 28px;
}

.cloapasableSettings .module-permission-table-row.td .module-permission-col[aria-expanded="true"] {
    background: url(../images/minus.png) no-repeat 70px center;
    background-size: 25px 25px;
    line-height: 28px;
}

.module-permission-checkbox input {
    width: 20px;
    height: 20px;
}

.module-permission-checkbox {
    display: flex;
    justify-content: center;
}

@media(max-width: 814px) {
    .module-permission-col {
        min-width: 125px;
    }

    .module-permission-table-row.th .module-permission-col:first-child,
    .module-permission-table-row.td .module-permission-col:first-child {
        min-width: 200px;
    }

    .module-permission-table-row.th,
    .module-permission-table-row.td {
        border: none;
    }

    .module-permission-table-row.th .module-permission-col {
        border-top: 1px solid #DBDEE7;
        border-bottom: 1px solid #DBDEE7;
    }

    .module-permission-table-row.td .module-permission-col {
        border-top: 1px solid #DBDEE7;
    }
}

/*----------------------09-02--------------------*/

.page-loader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Grey background */
    z-index: 9999;
  }
  
  .page-loader.visible {
    display: block;
  }
  
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px; /* Half of width */
    margin-left: -25px; /* Half of height */
  }
  
  @-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  li.dropdown-toggle-submenu {
    position: relative;
  }
  li.dropdown-toggle-submenu ul.submenu {
    display: none;
    list-style: none;
  }
  li.dropdown-toggle-submenu:hover ul.submenu {
    list-style: none;
    position: absolute;
    left: 99%;
    top: 0%;
    padding: 8px;
    background-color: #00205c;
    display: block;
    border-radius: 3px;
  }


  body .table-scroll {
    overflow: auto;
    /* max-height: calc(100vh - 430px); */
    position: relative;
  }

  .table-scroll thead {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    background-color: white; /* Ensure table header is visible */
    z-index: 1; /* Ensure the table header is above other content */
  }

  .ml-32 {
    margin-left: 32px;
  }
  .ml-48 {
    margin-left: 48px;
  }

  .zindex-10 {
    z-index: 10;
  }

  .zindex-0{
    z-index: 0 !important;
  }

  .datepicker-transaction{
    height : 38px !important;
  }

.text-gray-300 {
    color: #dddfeb!important;
}
.border-left-gmr {
    border-left: 0.25rem solid #4e73df!important;
}
.border-left-roar {
    border-left: 0.25rem solid #1cc88a!important;
}
.border-left-roer {
    border-left: 0.25rem solid #36b9cc!important;
}
.border-left-cr {
    border-left: 0.25rem solid #f6c23e!important;
}
.border-left-atr {
    border-left: 0.25rem solid #ba9dd9!important;
}
.border-left-cashr {
    border-left: 0.25rem solid #8bc972!important;
}
.border-left-ocfr {
    border-left: 0.25rem solid #aba70b!important;
}
.border-left-dr {
    border-left: 0.25rem solid #809f8a!important;
}
.border-left-dter {
    border-left: 0.25rem solid #d786bd!important;
}
.border-left-icr {
    border-left: 0.25rem solid #e78d8dfa!important;
}
.border-left-dscr {
    border-left: 0.25rem solid #5787a5fa!important;
}
.border-left-dscr {
    border-left: 0.25rem solid #5787a5fa!important;
}
.border-left-aturnr {
    border-left: 0.25rem solid #43c923fa!important;
}
.border-left-itr {
    border-left: 0.25rem solid #b15cdbfa!important;
}
.border-left-dsiir {
    border-left: 0.25rem solid #2c6db3fa!important;
}

.text-gmr {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb),var(--bs-text-opacity))!important;
}
.text-roar {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb),var(--bs-text-opacity))!important;
}
.text-roer {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb),var(--bs-text-opacity))!important;
}
.text-cr {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb),var(--bs-text-opacity))!important;
}
.text-atr {
    --bs-text-opacity: 1;
    color: #ba9dd9!important;
}
.text-cashr {
    --bs-text-opacity: 1;
    color: #8bc972!important;
}
.text-ocfr {
    --bs-text-opacity: 1;
    color: #aba70b!important;
}
.text-dr {
    --bs-text-opacity: 1;
    color: #809f8a!important;
}
.text-dter {
    --bs-text-opacity: 1;
    color: #d786bd!important;
}
.text-icr {
    --bs-text-opacity: 1;
    color: #e78d8dfa!important;
}
.text-dscr {
    --bs-text-opacity: 1;
    color: #5787a5fa!important;
}
.text-aturnr {
    --bs-text-opacity: 1;
    color: #43c923fa!important;
}
.text-itr {
    --bs-text-opacity: 1;
    color: #b15cdbfa!important;
}
.text-dsiir {
    --bs-text-opacity: 1;
    color: #2c6db3fa!important;
}

.create_adjustment_modal .form-control {
    height: 38px;
}
.personal-balance-sheet table {
    width: 100%;
    border-collapse: collapse;
}
.personal-balance-sheet td, .personal-balance-sheet th {
    border: 1px solid black;
    padding: 5px;
    vertical-align: top;
}
.personal-balance-sheet input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
    border: 1px solid #898484;
    border-radius: 5px;
}
.personal-balance-sheet input:focus, .personal-balance-sheet input:active, .personal-balance-sheet input:focus-within, .personal-balance-sheet input:focus-visible {
    border-color: #222;
    outline: none;
}
.personal-balance-sheet .header, .personal-balance-sheet .collapsible-header {
    background: #1560C5;
    color: #fff;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    cursor: pointer;
}
.personal-balance-sheet .header-title {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
}
.personal-balance-sheet .header-spouse {
    background-color: #1560C5;
    color: #fff;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
}
.personal-balance-sheet .balance-header {
    background-color: #d3d3d3;
    text-align: center;
    font-weight: bold;
}
.personal-balance-sheet .total-row, .personal-balance-sheet .networth-row {
    background-color: #c6e4ff;
    font-weight: bold;
}
.personal-balance-sheet .number-input {
    text-align: right;
}
.personal-balance-sheet .button {
    color: blue;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
}
.personal-balance-sheet .collapsible-content {
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}
.personal-balance-sheet input::placeholder {
    font-size: 12px;
}

.input-disabled-look {
    background-color: #e8ecf0;
    color: #6c757d;
    cursor: not-allowed;
}

.modal-custom-xxl .modal-dialog.modal-xl {
    max-width: 90%;
}

.grey-background {
    background-color: #E9ECEF;
}

.grey-background th, .grey-background td{
    background-color: #E9ECEF;
}

.clickable_link {
    color: #1560C5 !important;
    text-align: center;
    cursor: pointer;
}