.openning-balance table {
    width: 100%;
    border-collapse: collapse;
}
.boder-padding-none > tr > td {
    border: none !important;
    padding: 0 !important;
}
.openning-balance td, .openning-balance th {
    border: 1px solid black;
    padding: 5px;
    vertical-align: top;
}
.openning-balance input {
    width: 90%;
    box-sizing: border-box;
    padding: 5px 10px;
    border: 1px solid #898484;
    border-radius: 5px;
}
.openning-balance input:focus, .openning-balance input:active, .openning-balance input:focus-within, .openning-balance input:focus-visible {
    border-color: #222;
    outline: none;
}
.openning-balance .header, .openning-balance .collapsible-header {
    background-color: #1560C5;
    color: #fff;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    cursor: pointer;
}
.openning-balance .header-title {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
}
.openning-balance .header-spouse {
    background-color: #ffff00;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
}
.openning-balance .balance-header {
    background-color: #d3d3d3;
    text-align: center;
    font-weight: bold;
}
.openning-balance .total-row, .openning-balance .networth-row {
    background-color: #c6e4ff;
    font-weight: bold;
}
.openning-balance .number-input {
    text-align: right;
}
.openning-balance .button {
    color: blue;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
}
.openning-balance .collapsible-content {
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}
.openning-balance input::placeholder {
    font-size: 12px;
}
