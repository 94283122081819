.date-picker-container {
    position: relative;
    /* display: inline-block; */
  }
  
  .date-picker-container .react-datepicker-wrapper {
    width: 100%;
  }
  
  .date-picker-container .react-datepicker__input-container {
    display: flex;
    align-items: center;
  }
  
  .date-picker-container .calendar-icon {
    position: absolute;
    right: 10px;
    top: 65%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #666;
  }
  